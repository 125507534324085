import React, { useRef } from "react";
import { AllProjects } from "../utils/constant";
import { openNewTb } from "../utils/helper";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const ProjectSection = () => {
    const container = useRef(null);

    useGSAP(
        () => {
            const boxes = gsap.utils.toArray('.EachProject');
            boxes.forEach((box, index) => {
                gsap.from(box, {
                    x: index % 2 === 0 ? 300 : -300, // Alternate directions for visual appeal
                    opacity: 0, // Fade-in effect
                    duration: 1, // Animation duration
                    ease: "power3.out", // Smooth easing function
                    scrollTrigger: {
                      trigger: box,
                      start: "top 80%", // Start animation when box enters viewport
                      end: "top 30%", // Animation ends at this scroll position
                      scrub: 1, // Smooth scroll-tied animation
                    },
                });
            });
        },
        { scope: container }
    );

    return (
        <>
            <div id="Projects" className="w-[80vw] mx-[10vw] py-[20vh] project-section scroll-mt-[70px]">
                <p className="text-4xl font-semibold">Projects</p>
                <p className="text-2xl font-semibold text-gray-500 mt-5">Welcome to my digital playground — where I turn coffee into code, Wi-Fi into gossip hubs, and login systems into bouncers with irresistible charm. These are my most recent creations — dive in and enjoy the ride!</p>

                <div ref={container} className="mt-5 overflow-hidden w-100 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
                    <EachProject />
                </div>
            </div>
        </>
    )
}

export default ProjectSection;

const EachProject = () => {
    return AllProjects.map((projectELe) => (
        <div className="EachProject">
            <section onClick={() => openNewTb(projectELe.website)} className="bg-gray-200/25 p-1 rounded-xl transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-105 cursor-pointer shadow-md hover:shadow-xl">
                <div className="flex justify-between m-1 w-100 gap-2">
                    <div className="flex gap-1">
                        <div className="w-[10px] h-[10px] bg-red-500 rounded-[50%]"></div>
                        <div className="w-[10px] h-[10px] bg-orange-500 rounded-[50%]"></div>
                        <div className="w-[10px] h-[10px] bg-green-500 rounded-[50%]"></div>
                    </div>
                    <div className="w-[100%] rounded bg-white"></div>
                    <div className="flex gap-1">
                        <svg className="stroke-2 stroke-gray-500" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"><path d="m12 19-7-7 7-7"></path><path d="M19 12H5"></path></svg>
                        <svg className="stroke-2 stroke-gray-500" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
                    </div>
                </div>
                <div className="w-100 aspect-video overflow-hidden rounded-xl bg-white mt-2 m-1">
                    <img src={projectELe.imgSrc} className="object-fill h-[100%] w-100" />
                </div>
            </section>
            <div className="project-descriptions mt-5">
                <p className="text-2xl font-semibold">{projectELe.title}</p>
                <p className="text-xl text-gray-500 font-semibold mt-2">{projectELe.description}</p>
                <a className="text-xl text-gray-800 underline font-semibold inline-block  mt-2 " href={projectELe.website} target="_blank">View Project</a>
            </div>
        </div>)
    )
}