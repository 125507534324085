import React, { useEffect, useState } from "react";
import { linkedinLink, myDetails, NavBarHeight } from "../utils/constant";
import { TextHighLight, TextTyping } from "./CustomUI";
import { openNewTb } from "../utils/helper";

const MyProfile = ({customClass, stickyTop}) => (
  <img className={`
    ${customClass}
    my-profile 
    shadow-xl
    border-2 border-white
    transition delay-150 duration-300 ease-in-out
    bg-gradient-to-r from-gray-100 to-gray-500 
    hover:from-pink-500 hover:to-orange-500 
    rounded-2xl
    max-w-[250px]
    ${stickyTop ? 'ml-auto' :'mx-auto'}
    mb-10
  `} style={stickyTop ? {position: 'sticky', top: NavBarHeight} : {}} src="https://avatars.githubusercontent.com/u/82099692?v=4" alt="my profile image" />
)

let timeIntervalRef;

const HeroSection = () => {
  const [highLightText, sethighLightText] = useState(0);

  useEffect(() => {
    startTextHighlighting();

    return () => {
      clearInterval(timeIntervalRef);
    }
  }, [])

  const startTextHighlighting = () => {
    sethighLightText(0);
    
    timeIntervalRef = setInterval(() => {
      sethighLightText(prev => {
        // console.log("intervalCallBack", prev);
        if(prev == 100) clearInterval(timeIntervalRef)
        return prev + 1;
      });
    }, [500])
  }

  const goToLinkedIn = () => {
    openNewTb(linkedinLink)
  }

  return (
    <>
      <section id="About" className="w-[80vw] mx-[10vw] md:pt-[50px] hero-section sm:grid grid-cols-3 justify-between scroll-mt-[70px]">

        <MyProfile customClass={'block sm:hidden'} />

        <section className="about-section col-span-2">
          <p className="text-5xl font-semibold ">Hello! <TextTyping delayMs={50} >I'm Vishnu, a software developer</TextTyping></p>
          <p className="text-2xl font-semibold text-gray-500 mt-5 " >I'm a passionat<TextHighLight hiLghtNo={2} curNo={highLightText} bgClr={"bg-sky-500/25"} >e Fullstack </TextHighLight>developer with over a year of experience creating web applications that are as functional as they are <TextHighLight hiLghtNo={3} curNo={highLightText} bgClr={"bg-green-500/25"} >visually stunning</TextHighLight>. Whether it’s <TextHighLight hiLghtNo={4} curNo={highLightText} bgClr={"bg-red-500/25"} >crafting sleek U</TextHighLight>Is with animations that leave users in awe or implementing secure systems like my very ow<TextHighLight hiLghtNo={5} curNo={highLightText} bgClr={"bg-orange-500/25"} >n Unified Login System</TextHighLight>, I thrive on turning ideas into reality.</p>
          <p className="text-2xl font-semibold text-gray-500 my-3" >When I’m not busy<TextHighLight hiLghtNo={6} curNo={highLightText} bgClr={"bg-amber-500/25"} > coding or perfecting p</TextHighLight>rojects like <TextHighLight hiLghtNo={7} curNo={highLightText} bgClr={"bg-yellow-500/25"} >ChatHere </TextHighLight>- an online chatting platform with features so unique it might just<TextHighLight hiLghtNo={8} curNo={highLightText} bgClr={"bg-lime-500/25"} > make your Wi-Fi jea</TextHighLight>lous - you’ll find me exploring the ever-evolving tech world, dreaming up new ways to <TextHighLight hiLghtNo={9} curNo={highLightText} bgClr={"bg-green-500/25"} >make web development cooler. </TextHighLight></p>
          <p className="text-2xl font-semibold text-gray-500 my-3" >Oh, and did I mention<TextHighLight hiLghtNo={10} curNo={highLightText} bgClr={"bg-fuchsia-500/25"} > I lead a team of developers</TextHighLight>? That means I not only write code but also <TextHighLight hiLghtNo={11} curNo={highLightText} bgClr={"bg-rose-500/25"} >wield the magical skill of making meetings </TextHighLight>a little less boring. </p>
          <p className="text-2xl font-semibold text-gray-500 my-3">Life motto? <TextHighLight hiLghtNo={12} curNo={highLightText} bgClr={"bg-violet-500/25"} ><span className="italic">"If it moves, animate it; if it doesn’t, debug it until it does!"</span></TextHighLight></p>
          <button onClick={goToLinkedIn} className="bg-gray-800 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 cursor-pointer hover:bg-black text-2xl text-semibold my-5 text-gray-100 shadow-lg py-3 px-9 rounded-xl">View LinkedIn</button>
        </section>
        
        <MyProfile stickyTop={true} customClass={'hidden sm:block'} />
      </section>
    </>
  )
};

export default HeroSection; 
