import React, { useRef } from "react";
import { NavBarHeight } from "../utils/constant";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const AllExperience = [
    {
        role: 'React Js Developer',
        from: 'JAN 24',
        to: 'Present',
        company: 'IdeaHelix',
        description: [
            '11',
            '222',
            '3333'
        ]
    },
    {
        role: 'React Js Developer Intern',
        from: 'JULY 24',
        to: 'JAN 24',
        company: 'IdeaHelix',
        description: [
            '11',
            '222',
            '3333'
        ]
    },
    {
        role: 'Web Developer Intern',
        from: 'AUG 23',
        to: 'SEP 23',
        company: 'Lets Grow More',
        description: [
            '11',
            '222',
            '3333'
        ]
    },
]

const Experience = () => {
    const container = useRef(null);
    const topHeight = NavBarHeight;

    return (
        <>
            <div id="Experience"  ref={container} className="w-[80vw] mx-[10vw] my-[100px] experience-section scroll-mt-[70px]">
                <p className="text-4xl font-semibold">Experience</p>
                <p className="text-2xl font-semibold text-gray-500 mt-5">My skills are like a full-stack toolkit — ranging from crafting dynamic UIs with React.js to building robust backend systems with Express.js and Node.js. Add in DSA wizardry, C++ precision, JavaScript mastery, and a knack for logical thinking, and you've got a developer ready to tackle any challenge with style!</p>
                {/* <div className="experience-list" >
                    <div className="experince-box border-2 rounded-xl">
                        <p>Junior ReactJs Developer</p>
                        <p>IdeaHelix</p>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Experience;