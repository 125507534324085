import React from "react";
import { NavBarHeight } from "../utils/constant";
import HeroSection from "../components/HeroSection";
import ProjectSection from "../components/ProjectSection";
import Skills from "../components/Skills";
import Experience from "../components/Experience";

const Home = () => {
    return (
        <>
        <div className="home" style={{paddingTop: NavBarHeight}}>
            <HeroSection />
            <Skills />
            <ProjectSection />
            <Experience />
        </div>
        </>
    )
}

export default Home;